<template>
  <div class="home-panel">
    <div class="container">
      <div class="head">
        <span class="before-line"></span>
        <h3>{{ title }}<small>{{ subTitle }}</small></h3>
        <!-- 右上角插槽 -->
        <slot name="right"/>
      </div>
      <!-- 默认插槽，面板内容 -->
      <slot/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomePanel',
  props: {
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 副标题
    subTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="less">
.home-panel {
  background-color: #fff;

  .container {
    background-color: #fff;
  }

  .head {
    padding: 40px 0;
    display: flex;
    align-items: flex-end;

    h3 {
      flex: 1;
      font-size: 25px;
      font-weight: bold;
      margin-left: 6px;
      height: 35px;
      line-height: 35px;

      small {
        font-size: 25px;
        color: #999;
        margin-left: 20px;
      }
    }

    .before-line {
      background-color: #ffb700;
      display: block;
      height: 35px;
      width: 6px;
      margin-right: 10px;
    }
  }
}
</style>
