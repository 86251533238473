<template>
  <LeftTitle title="解决方案" :hava-slot="true">
    <template v-slot:right>
      <BaseMore :path="`/productadv`"/>
    </template>
    <div class="special-list">
      <div class="special-item" v-for="item in solveList" :key="item.id">
        <RouterLink :to="toProductAdvSolveDetailPrefix+item.articleId">
          <img :src="item.imgPath" :alt="item.articleTitle"/>
          <div class="meta">
            <p class="title">
              <span class="top ellipsis">{{ item.articleTitle }}</span>
              <span class="sub ellipsis">{{ item.characteristic }}</span>
            </p>
          </div>
        </RouterLink>
      </div>
    </div>
  </LeftTitle>
</template>

<script>
import HomePanel from './home-panel'
import BaseMore from "@/components/library/base-more.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {findProductAdvSolveList, toProductAdvSolveDetailPrefix} from "@/api/productadv";
import {onMounted, ref} from "vue";
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  name: 'HomeAdvice',
  components: {LeftTitle, FontAwesomeIcon, BaseMore, HomePanel},
  setup() {
    const solveList = ref({});
    const pageParams = {
      pageSize: 3,
    }
    onMounted(() => {
      findProductAdvSolveList(pageParams).then(res => {
        solveList.value = res.data
      })
    })
    return {solveList, toProductAdvSolveDetailPrefix}
  }
}
</script>

<style scoped lang='less'>
.home-panel {
  background: #f5f5f5;
}

.sub {
  margin-bottom: 2px;

  a {
    padding: 2px 12px;
    font-size: 16px;
    border-radius: 4px;

    &:hover {
      background: @baseColor;
      color: #fff;
    }

    &:last-child {
      margin-right: 80px;
    }
  }
}

.special-list {
  height: 310px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .special-item {
    width: 47vh;
    background: #fff;
    .hoverShadow();

    a {
      display: block;
      width: 100%;
      height: 288px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .meta {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent 50%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 288px;

        .title {
          position: absolute;
          bottom: 0px;
          left: 0;
          padding-left: 16px;
          width: 70%;
          height: 70px;

          .top {
            color: #fff;
            font-size: 22px;
            display: block;
          }

          .sub {
            display: block;
            font-size: 19px;
            color: #999;
          }
        }

        .price {
          position: absolute;
          bottom: 25px;
          right: 16px;
          line-height: 1;
          padding: 4px 8px 4px 7px;
          color: @priceColor;
          font-size: 17px;
          background-color: #fff;
          border-radius: 2px;
        }
      }
    }

    .foot {
      height: 72px;
      line-height: 72px;
      padding: 0 20px;
      font-size: 16px;

      i {
        display: inline-block;
        width: 15px;
        height: 14px;
        margin-right: 5px;
        color: #999;
      }

      .like,
      .view {
        float: left;
        margin-right: 25px;
        vertical-align: middle;
      }

      .reply {
        float: right;
        vertical-align: middle;
      }
    }
  }
}
</style>
